<!-- =========================================================================================
    File Name: TableExpandableData.vue
    Description: Create expandable rows which have custom data
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card title="แก้ไขข้อมูลบัญชี">
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mt-4" v-validate="'required|alpha_num|max:20'" label="หมายเลขบัญชี"
          placeholder="กรุณาใส่หมายเลขบัญชี" v-model="new_data.bankno" name="bankno" />
        <span class="text-danger text-sm" v-show="errors.has('bankno')">{{ errors.first('bankno') }}</span>
        <vs-input class="w-full mt-4" v-validate="'required|alpha_num|max:20'" label="Username" placeholder="กรุณาใส่ Username"
          v-model="new_data.username" name="username" />
        <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>
        <vs-input class="w-full mt-4" v-validate="'numeric|max:10'" label="เบอร์โทรศัพท์"
          placeholder="กรุณาใส่เบอร์โทรศัพท์ " v-model="new_data.phone" name="phone" />
        <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
        <vs-input v-if="this.$route.params.bank === 'bank_transfer' " class="w-full mt-4"  label="DeviceTD" placeholder="กรุณาใส่ DeviceTD"
          v-model="new_data.deviceId" type="deviceId" name="deviceId" />
        <vs-input v-if="this.$route.params.bank === 'bank_transfer_kbank' " class="w-full mt-4"  label="Code" placeholder="กรุณาใส่ Code"
          v-model="new_data.code" type="code" name="code" />

      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mt-4" v-validate="'required'" label="ชื่อบัญชี"
          placeholder="กรุณาใส่ชื่อบัญชี" v-model="new_data.fullname" name="fullname" />
        <span class="text-danger text-sm" v-show="errors.has('fullname')">{{ errors.first('fullname') }}</span>
        <vs-input class="w-full mt-4"  label="Password" placeholder="กรุณาใส่ Password"
          v-model="new_data.password" type="password" name="password" />
        <vs-input v-if="this.$route.params.bank === 'bank_transfer_kbank' || this.$route.params.bank === 'bank_transfer' " class="w-full mt-4"  label="PIN" placeholder="กรุณาใส่ PIN"
          v-model="new_data.pin" type="pin" name="pin" />


      </div>
      <span class="text-danger text-sm mt-5 ml-6">เพื่อความปลอดภัย ระบบจะไม่แสดง Password , Pin , DeviceId เก่าของท่าน กรุณากรอกข้อมูลใหม่ที่ต้องการแก้ไขในช่องว่างได้เลย</span>
    </div>
    <div class="mt-8 flex flex-wrap items-center justify-end">
      <vs-button color="success" class="ml-4 mt-2" @click="Save">ยืนยัน</vs-button>
    </div>
  </vx-card>
</template>

<script>
import {
  Validator
} from 'vee-validate'
import vSelect from 'vue-select'
import axios from '../../../../axios'

const dict = {
  custom: {
    username: {
      required: 'กรุณากรอกข้อมูล',
      alpha_num: 'ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น',
      max:'กรุณากรอกตัวอักษรไม่เกิน20ตัว'
    },
    password: {
      required: 'กรุณากรอกข้อมูล',
      decimal: 'ต้องเป็นตัวเลขหรือจุดทศนิยมเท่านั้น'
    },
    fullname: {
      required: 'กรุณากรอกข้อมูล'
    },
    bankno: {
      required: 'กรุณากรอกข้อมูล',
      max:'กรุณากรอกตัวอักษรไม่เกิน10ตัว'
    },
    phone: {
      required: 'กรุณากรอกข้อมูล',
      numeric: 'กรุณากรอกตัวเลขเท่านั้น',
      max:'กรุณากรอกตัวอักษรไม่เกิน10ตัว'
    }
  }
}
Validator.localize('en', dict)
export default {
  components: {
    vSelect
  },
  data () {
    return {
      status: false,
      bankfrom: this.$route.params.bank,
      bankid: this.$route.params.id,
      bankno: '',
      fullname: '',
      username: '',
      password: '',
      phone: '',
      bankbank: '',
      databank: [],
      new_data:{
        bankno: '',
        fullname: '',
        username: '',
        password: '',
        phone: '',
        deviceId : '',
        pin : '',
        code : ''
      },
      info_log: ''
    }
  },
  async mounted () {
    await axios.get(`settingwallet/editbankwithdraw/${this.$route.params.id}/${this.$route.params.bank}`)
      .then(response => (this.databank = response.data))
    this.new_data.bankno = this.databank[0].bank_number
    this.new_data.fullname = this.databank[0].bank_fullname
    this.new_data.username = this.databank[0].bank_username
    this.new_data.phone = this.databank[0].bank_phone


  },
  methods: {
    async Save () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          await axios
            .post('settingwallet/updatebankwithdraw', {
              bankfrom: this.bankfrom,
              bankid: this.bankid,
              bankno: this.new_data.bankno,
              fullname: this.new_data.fullname,
              username: this.new_data.username,
              password: this.new_data.password,
              phone: this.new_data.phone,
              pin : this.new_data.pin,
              code : this.new_data.code,
              deviceId : this.new_data.deviceId

            }).then(response => (this.status = response.data))
          if (this.status.status === true) {
            this.$vs.notify({
              time: 3000,
              color: 'success',
              position: 'top-right',
              icon: 'check_box',
              title: this.status.info
            })


            setTimeout(() => { this.$router.push('/SettingWalletWithdraw') }, 1500)
          } else {
            this.$vs.notify({
              time: 8000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'บันทึกไม่สำเร็จ',
              text: this.status.info
            })
          }
        } else {
          this.$vs.notify({
            time: 8000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: 'ไม่ได้กรอกข้อมูล',
            text: 'กรุณากรอกข้อมูลให้ครบถ้วน'
          })

        }
      })
    },
    async comparedata () {
      if (this.databank[0].bank_number !== this.new_data.bankno) {
        this.info_log += `เลขบัญชี :    ${  this.databank[0].bank_number  } > ${  this.new_data.bankno  } `
      }
      if (this.databank[0].bank_fullname !== this.new_data.fullname) {
        this.info_log += `ชื่อบัญชี :     ${  this.databank[0].bank_fullname  } > ${  this.new_data.fullname  } `
      }
      if (this.databank[0].bank_username !== this.new_data.username) {
        this.info_log += `username :   ${  this.databank[0].bank_username  } > ${  this.new_data.username  } `
      }
      if (this.databank[0].bank_password !== this.new_data.password) {
        this.info_log += `password :  ${  this.databank[0].bank_password  } > ${  this.new_data.password} `
      }
      if (this.databank[0].bank_phone !== this.new_data.phone) {
        this.info_log += `เบอร์โทรศัพท์ :  ${  this.databank[0].bank_phone  } > ${  this.new_data.phone} `
      }
    }
  }
}

</script>
